<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div class="col-lg-5 col-12" v-if="IsEditing">
                    <label for="product" class="form-label fw-bold">Product</label>
                    <input
                        type="text"
                        class="form-control"
                        :value="item.product ? item.product.name : ''"
                        disabled="disabled"
                    />
                    <p></p>

                </div>
                <div class="col-lg-5 col-12" v-else>
                    <label for="product" class="form-label fw-bold">Product</label>
                    <v-select
                        placeholder="Select Product / Service"
                        v-model="item.product_id"
                        :options="products"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-lg-3 col-12">
                    <label for="quantity" class="form-label fw-bold">
                        Quantity <slot v-if="! IsEditing">({{ commaFormat(stockInHand) }})</slot>
                    </label>
                    <input
                        v-model="item.transfer_qty"
                        type="number"
                        class="form-control text-end"
                        placeholder="Transfer Qty"
                        @blur="checkStockQty"
                        :disabled="IsEditing"
                    >
                </div>
                <div class="col-lg-3 col-12">
                    <label for="note" class="form-label fw-bold">Note</label>
                    <textarea
                        v-model="item.note"
                        placeholder="Description"
                        class="form-control"
                        rows="1"
                        :disabled="IsEditing"
                    />
                </div>
            </div>
            <div
                @click="$emit('onClose', index)"
                class="d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-x cursor-pointer font-medium-3">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, defineProps, inject, ref, watch} from 'vue';
import figureFormatter from "@/services/utils/figureFormatter";
import {useRoute} from "vue-router";
import handleStock from "@/services/modules/stock";

const $props = defineProps({
    item    : {
        type: Object
    },
    index   : {
        type: Number
    },
    products: {
        type: Array
    },
    fromId: {
        type: Number
    },
    IsEditing: {
        type: Boolean,
        default: false
    }
})
const $route = useRoute()

const stockInHand   = ref(0);
const {commaFormat} = figureFormatter()
const {fetchProductStock} = handleStock();
const showError = inject('showError')

//computed
const selectedProductId = computed(() => $props.item.product_id)
const selectedWarehouseId = computed(() => $props.fromId)

//watchers
watch(selectedWarehouseId, (newValue, oldValue) => {
    if(oldValue !== null) {
        $props.item.product_id = null;
    }
})
watch(selectedProductId, (newValue, oldValue) => {
    stockInHand.value = 0;
    let query = `?company_id=${$route.params.companyId}&product_id=${newValue}&warehouse_id=${$props.fromId}`
    if (newValue !== null) {
        fetchProductStock(query).then(res => {
            if(res.data){
                stockInHand.value = res.data.product_stock;
            }
        })

    }
})

//methods
const checkStockQty = () => {
    if($props.item.transfer_qty > stockInHand.value) {
        showError("Transfer quantity cannot be more than stock amount")
        $props.item.transfer_qty = stockInHand.value
    }
}
</script>
<style scoped>
.journal-line {
    border: 1px solid #f0f2f5;
}

.journal-line:hover {
    background: #f0f2f5;
}
</style>
